<template>
  <v-container fluid class="grey lighten-4">
    <v-row dense>
      <v-col cols="12">
        <organization-members :username="username"></organization-members>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrganizationMembers from '@/components/Organization/Members.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['username'],
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    page () {
      return this.getPageInfo(this.username)
    },
    isPs () {
      return this.page && this.page.pointsOnly
    }
  },
  methods: {
    setOpen () {
      this.user && this.user.vbl && this.panel.push(1)
    }
  },
  components: {
    OrganizationMembers
  }
}
</script>

<style scoped>
.bt {
  border-top: 1px solid white !important
}
>>> .v-expansion-panel__header {
  margin-bottom: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
</style>
