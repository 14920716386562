<template>
  <v-card flat>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Staff Members</v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense>
        <!-- Owners -->
        <v-col cols="12" class="title">
          Owner
        </v-col>
        <v-col cols="12">
          <v-list three-line>
            <v-list-item v-for="owner in owners" :key="owner.userId" @click.stop="select(owner)">
              <v-list-item-avatar>
                <img v-if="owner.user.avatar" :src="owner.user.avatar">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{`${owner.user.firstName} ${owner.user.lastName}`}}</v-list-item-title>
                <v-list-item-subtitle>{{owner.user.emails[0]}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{owner.isInvite ? 'Invited' : 'Active'}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="grey" text icon small><v-icon>fas fa-ellipsis-v</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <!-- Admins -->
        <template v-if="admins && admins.length">
          <v-col cols="12" class="title">
            Administrators
          </v-col>
          <v-col cols="12">
            <v-list dense>
              <template  v-for="(admin, i) in admins">
                <v-divider v-if="i > 0" :key="`d-${admin.userId}-${i}`"></v-divider>
                <v-list-item :key="`${admin.userId}-${i}`" @click.stop="select(admin)">
                  <v-list-item-avatar>
                    <img v-if="admin.user.avatar" :src="admin.user.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{admin.isInvite ? admin.user.emails[0] : `${admin.user.firstName} ${admin.user.lastName}`}}</v-list-item-title>
                    <v-list-item-subtitle>{{admin.isInvite ? 'Invited' : 'Active'}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="grey" text icon small><v-icon>fas fa-ellipsis-v</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </template>
        <!-- Coaches -->
        <template v-if="coaches && coaches.length">
          <v-col cols="12" class="title">
            Coaches
          </v-col>
          <v-col cols="12">
            <v-list dense>
              <template  v-for="(coach, i) in coaches">
                <v-divider v-if="i > 0" :key="`d-${coach.userId}-${i}`"></v-divider>
                <v-list-item :key="`${coach.userId}-${i}`" @click.stop="select(coach)">
                  <v-list-item-avatar>
                    <img v-if="coach.user.avatar" :src="coach.user.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{coach.isInvite ? coach.user.emails[0] : `${coach.user.firstName} ${coach.user.lastName}`}}</v-list-item-title>
                    <v-list-item-subtitle>{{coach.isInvite ? 'Invited' : 'Active'}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="grey" text icon small><v-icon>fas fa-ellipsis-v</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </template>
        <!-- Score Keepers -->
        <template v-if="refs && refs.length">
          <v-col cols="12" class="title">
            Score Keepers
          </v-col>
          <v-col cols="12">
            <v-list dense>
              <template  v-for="(ref, i) in refs">
                <v-divider v-if="i > 0" :key="`d-${ref.userId}-${i}`"></v-divider>
                <v-list-item :key="`${ref.userId}-${i}`" @click.stop="select(ref)">
                  <v-list-item-avatar>
                    <img v-if="ref.user.avatar" :src="ref.user.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ref.isInvite ? ref.user.emails[0] : `${ref.user.firstName} ${ref.user.lastName}`}}</v-list-item-title>
                    <v-list-item-subtitle>{{ref.isInvite ? 'Invited' : 'Active'}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="color3" text icon><v-icon>fas fa-ellipsis-v</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </template>
      </v-row>
      <!-- Buttons -->
      <v-row dense>
        <v-col class="text-right" cols="12" >
          <v-btn
            color="color3Text color3--text"
            class="mr-1"
            fab small
            bottom right
            @click.stop="addMember"
            :loading="loading"
            v-if="!noEdit"
          >
            <v-icon small>fas fa-plus</v-icon>
          </v-btn>
          <v-btn
            color="color3Text color3--text"
            fab small
            bottom right
            @click.stop="getMembers"
            :loading="loading"
          >
            <v-icon small>fas fa-redo-alt</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <member-drawer
      ref="drawer"
      :username="username"
      :affiliateId="affiliateId"
      :selectedAdmin="selectedAdmin"
      :adminData="adminData"
      @closed="selectedAdmin = null"
      @member-change="getMembers"
    ></member-drawer>
  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import { validationMixin } from 'vuelidate'
import { email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
const MemberDrawer = () => import('./MemberDrawer.vue')

export default {
  props: ['username', 'affiliateId'],
  mixins: [validationMixin],
  validations () {
    return {
      emailSearch: { email }
    }
  },
  data () {
    return {
      loading: false,
      drawer: false,
      adminData: null,
      selectedAdmin: null,
      dialogWindow: 'contact',
      edit: false,
      eRole: 'Admin',
      add: false,
      newAdmin: null,
      emailSearch: null,
      searching: false,
      searchResults: []
    }
  },
  computed: {
    ...mapGetters(['liveUpdates', 'user', 'getPageInfo']),
    organization () {
      return this.getPageInfo(this.username)
    },
    owners () {
      return this.adminData && this.adminData.filter(f => f.role === 'Owner' && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    hasOwner () {
      return this.owners && !!this.owners.find(f => !f.isInvite)
    },
    noEdit () {
      return this.hasOwner && this.affiliateId
    },
    admins () {
      return this.adminData && this.adminData.filter(f => f.role === 'Admin' && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    refs () {
      return this.adminData && this.adminData.filter(f => f.role.startsWith('Score') && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    coaches () {
      return this.adminData && this.adminData.filter(f => f.role.startsWith('Coach') && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    base () {
      return {
        first: '',
        last: '',
        full: '',
        role: '',
        emails: [],
        phones: []
      }
    },
    selected () {
      if (!this.selectedAdmin) {
        return this.base
      }
      const details = {
        first: this.selectedAdmin.user.firstName,
        last: this.selectedAdmin.user.lastName,
        full: `${this.selectedAdmin.user.firstName} ${this.selectedAdmin.user.lastName}`,
        role: this.selectedAdmin.role,
        emails: this.selectedAdmin.user.emails,
        phones: this.selectedAdmin.user.phones
      }

      return details
    },
    addError () {
      if (!this.newAdmin) return false
      if (!this.newAdmin.emails.includes(this.emailSearch)) return false
      const x = this.adminData.find(f => f.userId === this.newAdmin.id)
      return x ? `${this.newAdmin.firstName} ${this.newAdmin.lastName} is already a member.` : false
    },
    orgId () {
      return this.affiliateId || this.organization.id
    }
  },
  methods: {
    getMembers () {
      if (!this.username) return
      this.loading = true
      this.$VBL.get.admins(this.username)
        .then(r => { this.adminData = r.data })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    addMember () {
      this.$refs.drawer.addMember()
    },
    select (admin) {
      this.selectedAdmin = admin
      this.$refs.drawer.open()
      // this.drawer = true
    },
    editMe () {
      this.eRole = this.selected.role
      this.edit = true
    }
  },
  watch: {
    username: function (n, o) {
      if (n && o && n !== o) this.getMembers()
    },
    add: function (val) {
      if (val) {
        this.newAdmin = JSON.parse(JSON.stringify(this.base))
        if (this.affiliateId) this.eRole = 'Owner'
        this.dialogWindow = 'add'
        this.$refs.drawer.open()
      }
    }
  },
  created () {
    this.getMembers()
  },
  components: {
    MemberDrawer
  }
}
</script>

<style>

</style>
